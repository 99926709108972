// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"892a995ec8bcfc188f27fe511a9a5a7286e58416"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://50a2c4f0bc4c5d65304f9caca88349f7@o243106.ingest.us.sentry.io/4506636128354304",

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
